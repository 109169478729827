<template>
  <div class="container text-center">
    <div id="AppartementCarousel" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-indicators">
        <button
          type="button"
          data-bs-target="#AppartementCarousel"
          data-bs-slide-to="0"
          class="active"
          aria-label="Slide 1"
          aria-current="true"
        ></button>
        <button type="button" data-bs-target="#AppartementCarousel" data-bs-slide-to="1" aria-label="Slide 2" class=""></button>
        <button type="button" data-bs-target="#AppartementCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img class="bd-placeholder-img d-block w-100" src="../assets/DSC_0786.jpeg" style="max-height: 800px" />
        </div>
        <div class="carousel-item">
          <img class="bd-placeholder-img d-block w-100" src="../assets/DSC_0824.jpeg" style="max-height: 800px" />
        </div>
        <div class="carousel-item">
          <img class="bd-placeholder-img d-block w-100" src="../assets/DSC_0793.jpeg" style="max-height: 800px" />
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#AppartementCarousel" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#AppartementCarousel" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
  <div class="container mt-5">
    <div class="text-center">
      <div class="row">
        <h2 class="heading-section">Hof Buitenlust</h2>
      </div>
      <hr class="featurette-divider col-md-10 mx-auto mt-5" />
      <div class="row">
        <p class="lead">
          Binnen vindt u op de benedenverdieping een sfeervol ingerichte woonkamer met keuken en een gecombineerde badkamer en
          toilet met inloopdouche. De keuken bevat een inductiekookplaat, een vaatwasser en een koel- en vrieskast.
        </p>
      </div>
      <div class="row">
        <p class="lead">
          De benedenverdieping is voorzien van vloerverwarming en een sfeervolle houtkachel, waardoor het ook op koude dagen
          behaaglijk warm wordt in het appartement. Overal zijn in de inrichting kleine sfeerelementen aangebracht die verwijzen
          naar het boerenleven.
        </p>
      </div>
      <div class="row">
        <p class="lead">
          Via de trap aangekomen op de eerste verdieping vindt u een slaapkamer met een tweepersoonsbed en een slaapkamer met twee
          eenpersoonsbedden. Komt u met een kleine? Er is boven ook een campingbedje en een aankleedtafel aanwezig.
        </p>
      </div>
      <div class="row">
        <p class="lead">
          Tijd om door de kolossale mendeuren naar buiten te gaan? Laat u dan overweldigen door de grote tuin met vijver. Geniet
          van de avondzon op het terras achter op het perceel naast de vijver. Of vindt een lekker plekje om te ontspannen op het
          omsloten privé terras met een eettafel, stoelen, twee relaxfauteuils en een grote parasol.
        </p>
      </div>
    </div>
    <hr class="featurette-divider col-md-10 mx-auto mt-5" />
    <div class="row d-flex justify-content-center">
      <h3 class="heading-section text-center mb-5">Faciliteiten</h3>
      <div class="row col-md-10">
        <div class="col-md-4 mb-5">
          <h5>Slaapkamer - <i class="fas fa-bed"></i></h5>
          <p class="lead"><i class="fas fa-check-circle"></i> Handdoeken, zeep en wc-papier</p>
          <p class="lead"><i class="fas fa-check-circle"></i> Kledinghangers</p>
          <p class="lead"><i class="fas fa-check-circle"></i> Beddengoed</p>
          <p class="lead"><i class="fas fa-check-circle"></i> Raamverduisterende schermen</p>
          <p class="lead"><i class="fas fa-check-circle"></i> Kledingkast</p>
        </div>
        <div class="col-md-4 mb-5">
          <h5>Badkamer - <i class="fas fa-shower"></i></h5>
          <p class="lead"><i class="fas fa-check-circle"></i> Schoonmaakartikelen</p>
          <p class="lead"><i class="fas fa-check-circle"></i> Warm water</p>
          <p class="lead"><i class="fas fa-check-circle"></i> Douchegel</p>
        </div>
        <div class="col-md-4 mb-5">
          <h5>Buiten - <i class="fas fa-sun"></i></h5>
          <p class="lead"><i class="fas fa-check-circle"></i> Zijtuin (privé) – Volledig omheind</p>
          <p class="lead"><i class="fas fa-check-circle"></i> Tuinmeubels</p>
          <p class="lead"><i class="fas fa-check-circle"></i> Buiteneethoek</p>
        </div>
      </div>
      <div class="row col-md-10">
        <div class="col-md-4 mb-5">
          <h5>Keuken en eethoek - <i class="fas fa-coffee"></i></h5>
          <p class="lead"><i class="fas fa-check-circle"></i> Keuken</p>
          <p class="lead"><i class="fas fa-check-circle"></i> Koelkast</p>
          <p class="lead"><i class="fas fa-check-circle"></i> Kookbenodigdheden</p>
          <p class="lead"><i class="fas fa-check-circle"></i> Pannen, olie, zout en peper</p>
          <p class="lead"><i class="fas fa-check-circle"></i> Borden en bestek</p>
          <p class="lead"><i class="fas fa-check-circle"></i> Schalen, borden, bekers, enz.</p>
          <p class="lead"><i class="fas fa-check-circle"></i> Vaatwasser</p>
          <p class="lead"><i class="fas fa-check-circle"></i> Fornuis</p>
          <p class="lead"><i class="fas fa-check-circle"></i> Mini oven</p>
          <p class="lead"><i class="fas fa-check-circle"></i> Koffiezetapparaat Senseo</p>
          <p class="lead"><i class="fas fa-check-circle"></i> Wijnglazen</p>
        </div>
        <div class="col-md-4 mb-5">
          <h5>Amusement - <i class="fas fa-tv"></i></h5>
          <p class="lead"><i class="fas fa-check-circle"></i> TV</p>
          <h5 class="mt-5">Familie - <i class="fas fa-user-friends"></i></h5>
          <p class="lead"><i class="fas fa-check-circle"></i> Kinderstoel</p>
          <p class="lead"><i class="fas fa-check-circle"></i> Campingbed</p>
          <h5 class="mt-5">Verwarming en verkoeling - <i class="fas fa-temperature-high"></i></h5>
          <p class="lead"><i class="fas fa-check-circle"></i> Centrale verwarming</p>
          <p class="lead"><i class="fas fa-check-circle"></i> Houtkachel</p>
          <h5 class="mt-5">Veiligheid in huis - <i class="fas fa-user-shield"></i></h5>
          <p class="lead"><i class="fas fa-check-circle"></i> Rookmelder</p>
          <h5 class="mt-5">Internet en kantoor - <i class="fas fa-wifi"></i></h5>
          <p class="lead"><i class="fas fa-check-circle"></i> Wifi</p>
        </div>
        <div class="col-md-4 mb-5">
          <h5>Parkeren en faciliteiten - <i class="fas fa-parking"></i></h5>
          <p class="lead"><i class="fas fa-check-circle"></i> Gratis parkeren op het terrein</p>
          <h5 class="mt-5">Services - <i class="fas fa-tools"></i></h5>
          <p class="lead">
            <i class="fas fa-check-circle"></i> Bagage-opslag toegestaan voor het gemak van de gasten wanneer ze vroeg aankomen of
            laat vertrekken
          </p>
          <p class="lead"><i class="fas fa-check-circle"></i> Verhuurder zal je verwelkomen</p>
          <h5 class="mt-5">Locatiekenmerken - <i class="fas fa-home"></i></h5>
          <p class="lead"><i class="fas fa-check-circle"></i> Eigen entree</p>
          <p class="lead"><i class="fas fa-check-circle"></i> Aparte ingang in het gebouw</p>
        </div>
      </div>
    </div>
    <hr class="featurette-divider col-md-10 mx-auto mt-5" />
    <div class="text-center">
      <h3 class="heading-sectionmb-5">Huisregels</h3>
      <div class="row">
        <p class="lead">
          Aankomen kan tussen 15:00 - 21:00. Vertrekken kan tot 10:00. In het appartement is het niet toegestaan om te roken. Ook
          staat het appartement niet open voor huisdieren. We zien liever niet dat er feestjes of evenementen plaats vinden in of
          rond het appartement.
        </p>
      </div>
    </div>
    <hr class="featurette-divider col-md-10 mx-auto mt-5" />
    <div class="text-center">
      <h3 class="heading-sectionmb-5">Aanvullende regels</h3>
      <div class="row">
        <p class="lead">
          Hof Buitenlust staat bekend om de rust en ontspanning. Graag willen wij dit zo houden en accepteren daarom geen muziek
          of harde geluiden buiten op het erf. Om het huis netjes te houden voor volgende bezoekers, vragen we u binnen uw
          schoenen uit te doen. Zondag is voor ons een rustdag. Aankomst op zondag is daarom niet mogelijk.
        </p>
        <div class="text-center mt-3">
          <h5>Ook is het goed om te weten dat:</h5>
          <p class="lead"><i class="fa fa-minus"></i> Op het erf er genoeg ruimte is om te parkeren</p>
          <p class="lead">
            <i class="fa fa-minus"></i> Er maximaal €200 in rekening gebracht kan worden voor schade aan het appartement
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
@media only screen and (min-width: 1500px) {
  #AppartementCarousel {
    max-width: 1500px;
  }
}
</style>
